.btn {
    font-size: 1.3em !important;
    padding: 20px 42px !important;
    @media(max-width:768px) {

        font-size:1em !important;

    }
}

.nopadding {
    margin-right:0 !important;
    margin-left:0 !important;
    padding-right:0 !important;
    padding-left:0 !important;
}
.navbar-default {
    color: #FFF !important;
}

.carousel-control.left {
    background-image: linear-gradient(to right, rgba(38, 134, 192, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#802686c0', endColorstr='#00000000', GradientType=1) !important;
}

.carousel-control.right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(38, 134, 192, 0.5) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002686c0', endColorstr='#802686c0', GradientType=1) !important;
}
.dropdown-menu {
    border-radius:0;
}
.navbar-default .navbar-toggle {
    border-color: transparent !important;
}
.navbar-toggle .icon-bar {
    height:4px !important;
    width:36px !important;
    background-color: $azul !important;
}
.sweet-alert button {
    border-radius: 30px !important;
}
.form-control {
    background-color: transparent !important;
    background-image: none !important;
    border-top: 0px !important;
    border-left:0px !important;
    border-right:0px !important;
    border-bottom:1px solid $azul_light !important;
    border-radius: 0 !important;
    padding: 6px 12px !important;
    box-shadow:0px 0px 0px transparent !important;
}
.input-control-rounded {
    background-color: transparent !important;
    background-image: none !important;
    border: 1px solid #dceef8 !important;
    border-radius: 30px !important;
    box-shadow: 0 0 0 transparent !important;
    height: 65px !important;
    text-align: center;
}
.btn-blue {
    background-color: $azul !important;
    color: #FFF !important;
    &:hover {
        opacity: 0.8;
    }
}
select.form-control {
    font-size:1em !important;
}
.btn-default-outline {
    color: #fff;
    height: 76px;
    padding-top: 24px !important;
    width: 254px;
    background:transparent url("../img/button_white_outline2x.png") no-repeat scroll center center;
    background-size: 100% auto;
}
.badge {
    background-color: $azul !important;
}