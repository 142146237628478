html,
body {
    height: 100%;
    font-family: 'Nunito', sans-serif;
}

@import "backgrounds";

@import "texts";

.img-portfolio {
    margin-bottom: 30px;
}

.img-hover:hover {
    opacity: 0.8;
}

/* Home Page Carousel */

header.carousel {
    height: 50%;
}

header.carousel .item,
header.carousel .item.active,
header.carousel .carousel-inner {
    height: 100%;
}

header.carousel .fill {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}

/* 404 Page Styles */

.error-404 {
    font-size: 100px;
}

/* Pricing Page Styles */

.price {
    display: block;
    font-size: 50px;
    line-height: 50px;
}

.price sup {
    top: -20px;
    left: 2px;
    font-size: 20px;
}

.period {
    display: block;
    font-style: italic;
}

@import "carousel_fade";
@import "bootstrap-overrides";


/* Footer Styles */

footer {
    margin: 50px 0;
}

/* Responsive Styles */
@media(max-width:1200px) {
    @import "mobile1200";
}
@media(max-width:991px) {

    @import "mobile991";
}

@media(max-width:767px) {
    @import "mobile767";
}

