.txt-white {
    color:#FFF !important;
}
.txt-1dot5em {
    font-size:1.5em !important;
}
.txt-1dot9em {
    font-size:1.9em !important;
}
.txt-1dot3em {
    font-size:1.3em !important;
}
.txt-blue {
    color:#2586bd;
}

.txt-gray {
    color:#adadad;
}

.txt-big {
    font-size:13em !important;
}
.txt-font-special {
    font-family: 'Days One', sans-serif;
}