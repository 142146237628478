.bg-radial-blue {
    background: #58c6eb; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #58c6eb 0%, #2b81b4 72%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #58c6eb 0%,#2b81b4 72%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #58c6eb 0%,#2b81b4 72%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58c6eb', endColorstr='#2b81b4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    background-position:center bottom;
}

.bg-milk {
    background:#2b81b4 url("../../public/img/bgmilk.png") no-repeat scroll center bottom;
    height: 447px;
    width: 100%;
}
.bg-wood {
    background-image:url("../../public/img/woodfloor2x.png");
    background-color:#2b81b4;
    background-size:cover;
    background-position: center top;
}